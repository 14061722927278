@import 'src/assets/styles/utils/index.module.scss';

.container {
  width: 100%;
  background-color: $color-white;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 16px;
}

.communityName {
  margin-left: 15px;
  padding-bottom: 4px;
  border-bottom: 2px solid $color-burn-my-eyes;
  font-weight: bold;
  font-size: 16px;
}

.separator {
  flex: 1;
  height: 14px;
  margin-right: 10px;
  border-right: 1px solid $color-metal-grey;
}
