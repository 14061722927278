@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 100;
  display: flex;
  flex-direction: column;
  height: 35px;
  bottom: 0;
  color: #a0a0ac;
  font-size: 16px;
  font-weight: 500;

  footer {
    text-align: center;
    margin: 10px 0 0;
  }
}
