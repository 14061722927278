@import 'src/assets/styles/utils/index.module.scss';

.blockOuter {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  width: 321px;
  height: 83px;
  padding: 10px 10px 7px;
  border-radius: 7px;
  background-color: $color-vape;
  color: $color-ashy;
  font-weight: $fw-medium;
  cursor: pointer;

  @media (max-width: 500px) {
    width: 100%;
  }
}

.upperRow {
  display: flex;
}

.lowerRow {
  display: flex;
  align-items: center;
}

.infoWrapper {
  max-width: 80%;

  > div:first-child {
    width: 100%;
  }

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.iconMainWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  margin-right: 8px;
  background-color: $color-ace-blue;
  color: $color-white;
}

.iconMainWrapperCanary {
  background-color: $color-ace-blue;
  color: $color-white;
}

.iconMain {
  // width: 10px;
  // height: 10px;
}

.iconsWrapper {
  display: flex;
  margin-left: auto;
  padding-top: 7px;
}

.iconUser,
.iconMore {
  width: 12px;
  height: 12px;
}

.iconMore {
  margin-left: 14px;
}

.title {
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.5rem;
}

.description {
  //TODO - check design
  font-size: 0.9rem;
  line-height: 0.7rem;
}

.iconLocation {
  width: 12px;
  height: 12px;
  margin-right: 7px;
}

.location {
  overflow: hidden;
  max-width: calc(100% - 60px);
  font-size: 1.1rem;
  line-height: 1.2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.actionNumber {
  margin-left: 6px;
  font-weight: $fw-medium;
  font-size: 1.1rem;
}

.menuItem {
  justify-content: right;
  padding: 12px;
  background-color: transparent;
  color: $color-white;
  font-weight: $fw-medium;
  font-size: 1.2rem;
  font-family: $ff-sans;
  line-height: 1.3rem;

  svg {
    position: relative;
    z-index: 1;
  }
}

.menuItemBottomBorder {
  border-bottom: 1px solid $color-kinda-dirty;
}

.actionButtons {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  width: 56px;
  height: 26px;

  button {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &.oneButton {
    width: 40px;
  }
}

.action-play {
  background-color: $color-burn-my-eyes;
}

.action-stop {
  background-color: $color-metal-grey;
  color: $color-snow-in-april;
}

.action-pause {
  background-color: $color-ace-blue;
  color: $color-snow-in-april;
}

.action-bar-chart {
  background-color: $color-ashy;
  color: $color-oh-so-green;
}

.action-collaborate {
  background-color: $color-aubergine;
  color: $color-snow-in-april;

  svg {
    width: 14px;
    height: 14px;
  }
}
