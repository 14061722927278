@import 'src/assets/styles/utils/index.module.scss';

.contentWrapper {
  display: flex;
  flex: 7;
  //background-color: $color-snow-in-april;
  background-color: #fbf9f9;
  padding: 11px;
  flex-direction: column;
}

.energyContent {
  display: flex;
  flex: 1;
  background-image: url('../../../../assets/images/EnergyFlow/energyflow_eightagon_outline.png'),
    url('../../../../assets/images/EnergyFlow/energyflow_eightagon.png');
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.drawContainer {
  display: flex;
  flex: 1;
}

.mainIcon {
  position: absolute;
  display: block;
  float: none;
  width: 90px;
  height: 90px;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  z-index: 2;
}

.lineSelector {
  background: inherit;
}

.importLine {
  border-image: linear-gradient(
    209.34deg,
    rgba(252, 19, 85, 0.8) 13.5%,
    rgba(26, 72, 237, 0.8) 104.66%
  );
}

.exportLine {
  border-image: linear-gradient(
    216.27deg,
    rgba(93, 226, 115, 0.8) 15.63%,
    rgba(26, 72, 237, 0.8) 124.72%
  );
}

.lineContainer {
  position: absolute;
}
