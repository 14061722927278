@import 'src/assets/styles/utils/index.module.scss';
.container {
  padding-bottom: 100px;
  min-height: 125px;
}
.saveSettings {
  width: 100%;
  height: 45px;
  position: absolute;
  left: 0;
  bottom: 0;
  margin-top: 40px;
  background-color: $color-oh-so-green;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
