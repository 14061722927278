@import 'src/assets/styles/utils/index.module.scss';

.container {
  background: #fbf9f9;
  /* GSy New Brand/BurnMyEyes */

  border: 1px solid $color-burn-my-eyes;
  box-sizing: border-box;
  /* Shadow Card Background */

  box-shadow: 0px -4px 4px rgba(255, 255, 255, 0.25), 10px 10px 20px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 16px 14px;

  .keyResultsTabs {
    margin-top: 28px;

    button {
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;

    .title {
      font-family: $ff-sans;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;

      display: flex;
      align-items: center;
      color: $color-ashy;
    }

    .toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 52px;
      height: 21px;
      background: transparent;
      color: $color-ace-blue;
      border-radius: 5px;
      border: 1px solid $color-ace-blue;

      span {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  .cost {
    margin-top: 10px;
    color: $color-ashy;
    font-family: 'NB Akademie Std', sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 32px;

    .progressWrapper {
      margin-top: 30px;
    }

    .progressBenchmark {
      background-image: linear-gradient(270deg, #fc1355, #1a48ed);
      .progressBenchmarkBar {
        background-image: linear-gradient(270deg, #13fc54, #1a48ed);
      }
    }
  }
}

.block {
  border-radius: 5px;
  background-color: $color-vape;
}

.assetInfo {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  padding: 14px 15px;

  header {
    display: flex;
    align-items: center;
    justify-content: 'left';
    align-items: 'center';
    gap: 10px;
    .earningStatus {
      font-family: 'NB Akademie Std', sans-serif;
      color: $color-ashy;
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
    }

    .monthlyTag {
      height: 6px;
      font-family: $ff-sans;
      font-weight: 500;
      font-size: 9px;
      line-height: 18px;
      color: $color-ace-blue;
      display: flex;
      align-items: center;
      text-align: center;
      border: 1px solid $color-ace-blue;
      padding: 8px;
      border-radius: 5px;
    }
    .toolTipWrapper {
      position: absolute;

      .toolTip {
        position: absolute;
        top: 18%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 14;
        font-size: 14px;

        @media only screen and (min-width: 1920px) and (min-height: 1080px) {
          top: 27%;
        }
      }
    }
  }

  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $color-ashy;
    margin-top: 1rem;
    display: flex;
  }

  footer {
    .footerText {
      font-family: Roboto;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: $color-ashy;
      mix-blend-mode: normal;
      opacity: 0.6;
    }
  }
}

.differenceInfoTag {
  display: flex;
  align-items: center;
  color: $color-ashy;
  font-size: 1.1rem;

  p {
    font-family: 'NB Akademie Std', sans-serif;
    color: $color-ashy;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
  }

  span {
    font-weight: $fw-bold;
  }
}

.badge {
  display: flex;
  align-items: center;
  height: 20px;
  padding: 0 10px;
  border-radius: 10px;
  background-color: $color-ashy;
  color: $color-snow-in-april;
  font-size: 1rem;
  margin: 10px 0;
}

.horizontalWrapper {
  display: flex;
  flex-direction: row;
  .horizontalBlock {
    width: 240px;
    margin-right: 1rem;
  }
}
