.rowWrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;
  .leftColumn {
    flex: 5;
  }
  .rightColumn {
    flex: 6;
  }
  .fullColumn {
    flex: 11;
  }
}

.selfSufficiencyWrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  .selfItem {
    padding-bottom: 35px;
    padding-top: 10px;
  }
}

.worldMap {
  position: relative;
  height: 300px;
}
.theMap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.popupMini {
  margin-top: -30px;
}

.downloadResultContainer {
  position: absolute;
  right: 20px;
  top: 10px;
}
