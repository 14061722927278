@import 'src/assets/styles/utils/index.module.scss';

.assetsListWrapper {
  height: var(--assets-manager-scroll-max-height);
}

.assets-ul-grid {
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-right: -7px;
  margin-bottom: -7px;

  li {
    width: 33%;
    padding-right: 7px;
    padding-bottom: 7px;
  }
}

.assets-ul-list {
  li + li {
    margin-top: 6px;
  }
}

.blockWrapper {
  position: relative;
}

.blockHintActive {
  border: thin solid $color-oh-so-green;
  border-radius: 4px;
}

.searchWrapper {
  display: flex;
  margin-bottom: 15px;
}

.search {
  flex-grow: 1;
  margin-right: 5px;
}

.filtersWrapper {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;

  .sortContainer {
    width: 80px;
  }
}

.boxDescription {
  margin-bottom: 16px;
  font-family: $ff-sans-secondary;

  h3 {
    margin-bottom: 8px;
    font-weight: $fw-bold;
    font-size: 1.4rem;
  }

  p {
    font-weight: $fw-regular;
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
}

.boxDescriptionLight {
  color: $color-black;
}

.boxDescriptionDark {
  color: $color-snow-in-april;
}

.filterBtnWrapper {
  width: 22px;
  height: 22px;
  margin-left: 6px;
  border-radius: 4px;
  background: $color-metal-grey;
  color: $color-snow-in-april;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  button:disabled {
    opacity: 0.8;
  }
  &:first-child {
    margin-left: 0px;
  }
}

.filterBtnLarge {
  width: 50px;
  margin-right: auto;
  margin-left: 0;
  color: $color-ashy;
}

.filterBtnActive {
  background: $color-oh-so-green;
  color: $color-ashy;
}

.statusMessage {
  padding: 60px 0;
  text-align: center;
}

.totalAssets {
  padding-left: 10px;
  color: $color-metal-grey;
  font-weight: bold;
}
