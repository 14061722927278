@import 'src/assets/styles/utils/index.module.scss';

.listWrapper {
  display: flex;
  flex: 3;
  background-color: $color-vape;
}

.scroll {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
  max-height: 310px;
  margin-top: 5px;
}
