@import 'src/assets/styles/utils/index.module.scss';

.container {
  .emailInvitation {
    padding-bottom: 20px;

    & svg {
      margin-left: 0.75rem;
    }

    & > div {
      display: flex;
      justify-content: space-between;
      &:first-of-type {
        margin-bottom: 15px;
        align-items: center;
        h3 {
          font-size: 18px;
          font-weight: 500;
          color: $color-ashy;
        }
        p {
          display: flex;
          align-items: center;
          font-size: 11px;
          line-height: 12px;
          color: $color-metal-grey;
          svg {
            margin-right: 5px;
            transform: translateY(-1px);
          }
        }
      }
      &:last-of-type {
        align-items: flex-start;
      }
    }
  }
  .inviteHeader {
    font-weight: 700;
    font-size: 21px;
    line-height: 27px;
    color: $color-snow-in-april;
  }
  .inviteSubHeader {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;
    color: $color-soft-not-so-green;
    padding: 20px 0;
    display: block;
  }
  .inviteFormHeader {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $color-vape;
    margin: 0 0 10px;
  }
  .input {
    flex: 1;
    margin-right: 5px;
  }
  .line {
    width: 100%;
    height: 1px;
    border-bottom: thin solid $color-vape;
    position: relative;
    span {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      background-color: $color-snow-in-april;
      padding: 0 6px;
      font-size: 11px;
      line-height: 12px;
      color: $color-metal-grey;
    }
  }
  .description {
    font-size: 14px;
    line-height: 16px;
    color: $color-ashy;
    font-style: italic;
  }
  .uploadSheetLink {
    display: flex;
    gap: 10px;
    font-family: $ff-sans;
    font-size: 14px;
    line-height: 16px;
    color: $color-ace-blue;
    margin: 20px 0;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  }
}
