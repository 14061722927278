@import 'src/assets/styles/utils/index.module.scss';

.customResultHeaderWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  .topRightArea {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
}

.dailyMonthlyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 290px;
  height: 26px;
  border: 1px solid $color-ace-blue;
  border-radius: 4px;

  .text {
    display: flex;
    flex: 1;
    padding: 5px;
    font-size: 14px;
    line-height: 16px;
    color: $color-ace-blue;
    border-radius: 4px;
    justify-content: center;
    &.selected {
      background-color: $color-ace-blue;
      color: $color-vape;
    }
  }
}

.liveResultsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 175px;
  height: 26px;
  border: 1px solid $color-singularity-red;
  border-radius: 4px;
  padding: 6px;

  &.simulationResults {
    border-color: $color-ace-blue;
    flex-direction: row;
    gap: 4px;
    .text {
      color: $color-ace-blue;
      &::before {
        width: 0;
        height: 0;
        display: none;
      }
    }
  }

  .text {
    font-size: 14px;
    line-height: 16px;
    color: $color-singularity-red;

    &::before {
      content: '';
      position: relative;
      right: 10px;
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-singularity-red;
    }
  }
}

// put the homeName class to the left top corner of the container
.homeName {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
  gap: 8px;

  span {
    font-weight: 500;
    font-size: 18px;
  }

  .iconContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .borderIcon {
      width: 1.7px;
      height: 18px;
      background-color: $color-black;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.communityName {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
  border-bottom: 2px solid $color-oh-so-green;
  padding-bottom: 5px;
  span {
    font-weight: 500;
    font-size: 18px;
  }
}

.settingsActionButton {
  color: $color-white;
  background-color: $color-pretty-dark;
  font-size: 1.3rem;
  align-content: center;
}
