@import 'src/assets/styles/utils/index.module.scss';

.testArea {
  position: absolute;
  background: $color-vape;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  margin-left: -15px;
  margin-top: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
