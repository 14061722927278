@import 'src/assets/styles/utils/index.module.scss';

.rowWrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;
  .leftColumn {
    flex: 5;
  }
  .rightColumn {
    flex: 6;
  }
  .selfandprogress {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .selfSufficiencyWrapper {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      .selfItem {
        padding-bottom: 35px;
        padding-top: 10px;
      }
    }
  }
  .savingsRanking {
    background-color: $color-white;
    padding-top: 24px;
    padding-bottom: 32px;
    header {
      margin-bottom: 50px;
      h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}

.liveResultsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 175px;
  height: 26px;
  position: absolute;
  right: 20px;
  margin-top: -40px;
  border: 1px solid $color-singularity-red;
  border-radius: 4px;
  padding: 6px;

  .text {
    font-size: 14px;
    line-height: 16px;
    color: $color-singularity-red;

    &::before {
      content: '';
      position: relative;
      right: 10px;
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-singularity-red;
    }
  }
}

.dailyMonthlyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 290px;
  height: 26px;
  position: absolute;
  right: 210px;
  margin-top: -40px;
  border: 1px solid $color-ace-blue;
  border-radius: 4px;

  .text {
    display: flex;
    flex: 1;
    padding: 5px;
    font-size: 14px;
    line-height: 16px;
    color: $color-ace-blue;
    border-radius: 4px;
    justify-content: center;
    &.selected {
      background-color: $color-ace-blue;
      color: $color-vape;
    }
  }
}

.energyFlowWrapper {
  background: none;
  h3 {
    font-size: 22px !important;
  }
}
.energyFlowContent {
  padding: 0px;
}

.downloadResultContainer {
  position: absolute;
  right: 20px;
  top: 10px;
}
