@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  animation: rotate 2s linear infinite;

  & .path {
    stroke: $color-oh-so-green;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
