@import 'src/assets/styles/utils/index.module.scss';

$padding: 10px;

.container {
}

.formWrapper {
  margin-bottom: 50px;
}

.formButtonsWrapper {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
  padding-top: 10px;
  background-color: $color-snow-in-april;
}

.formButton {
  width: 100%;
  border-radius: 0;
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.label {
  font-family: $ff-sans;
  font-size: 12px;
  line-height: 14px;
  color: $color-ashy;
}

.paddingLR {
  padding: 0 $padding 0 $padding;
}

.header {
  display: flex;
  align-items: center;
  color: $color-pretty-dark;
  // padding: 15px 0;
  margin: 25px 0 10px 0;
}

.title {
  overflow: hidden;
  max-width: 13em;
  margin-right: 20px;
  font-family: $ff-sans;
  font-weight: $fw-bold;
  font-size: 1.6rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 19px;
}

.selectField {
  margin: 20px 0;
}

.switchWrapper {
  display: flex;
  align-items: center;
  margin-top: -1px;
  // border-top: thin solid rgba($color-metal-grey, 0.1);

  & + & {
    border-bottom: thin solid rgba($color-metal-grey, 0.1);
  }
}
