@import 'src/components/ModalCollaborationDeploy/ModalCollaborationDeploy.module.scss';

.modalBuildCommunity {
  display: flex;
  flex-direction: row;
  border-radius: $modalBorderRadius;
  padding: 0;
  position: relative;

  .imgContainer {
    min-width: 415px;
    min-height: 500px;
  }

  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 0 3rem;

    .closeBtn {
      position: absolute;
      right: 12px;
      top: 12px;
      margin: 0;
      width: 22px;
      height: 22px;
    }

    .heading {
      font-family: $ff-sans-landingPage;
      font-weight: 700;
      font-size: 30px;
      line-height: 32px;
      color: $color-snow-in-april;
      margin-bottom: 1.8rem;
    }

    .subHeading {
      font-family: $ff-sans;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: $color-metal-grey;
      margin-bottom: 4rem;
    }

    .nextBtn {
      width: 100%;
      margin-top: 1.5rem;
    }
  }
}
