.modalChartBrushMeasure {
  display: flex;
  flex: 1;
  margin-left: 17px;
  margin-right: 2px;
}

.modalChartBrushBorder {
  display: block;
  position: absolute;
  width: 2px;
  height: 34px;
  margin-left: 17px;
  border: 1px solid #a0a0ac;
  border-radius: 3px;
}

.modalResponsiveBrushContainer {
  padding-top: 2px;
}

.graphBrush {
  flex: 10;
  padding-left: 5px;
}

.brushContainer {
  position: relative;
  width: 100%;
  padding-bottom: 50px;
}
.brushContent {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
