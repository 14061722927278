@import 'src/assets/styles/utils/index.module.scss';

.container {
  .headingText {
    font-family: $ff-sans;
    line-height: 19px;
    color: $color-ashy;
    margin: 15px 0 8px 0;
    font-weight: $fw-bold;
    font-size: 1.6rem;
    width: 100%;
  }

  .selectField {
    margin-bottom: 20px;
  }
  .formButtonsWrapper {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100%;
    padding-top: 10px;
    background-color: $color-snow-in-april;
  }
  .formButton {
    width: 100%;
    border-radius: 0;
  }
}
