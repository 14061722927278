@import 'src/assets/styles/utils/index.module.scss';

.listHeader {
  margin-top: 17px;

  .tabsSelecor {
    display: flex;

    button {
      margin-right: 32px;
      color: $color-metal-grey;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      white-space: nowrap;

      &.active {
        position: relative;
        color: $color-ashy;
        font-weight: 500;

        &::after {
          content: '';
          position: absolute;
          bottom: -4px;
          left: 0;
          width: 100%;
          height: 1px;
          border: thin solid $color-oh-so-green;
        }
      }
    }
  }

  .counterAndFilters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 22px;
    margin-top: 24px;

    & > div {
      display: flex;

      button:not(:last-of-type) {
        margin-right: 6px;
      }
    }
  }

  .searchAndSelect {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 17px;

    .input {
      flex: 1;
    }
  }
}

.avatar {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border-radius: 50%;
  background-color: $color-metal-grey;
  background-size: cover;

  .small {
    width: 20px;
    height: 20px;
  }
}

.tag {
  padding: 3px 10px;
  border-radius: 9px;
  background-color: $color-ace-blue;
  color: $color-snow-in-april;
  font-weight: 300;
  font-size: 11px;
  font-family: $ff-sans-landingPage;
  line-height: 12px;
  letter-spacing: 0.04rem;
}

.usersList,
.assetsList {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 16px;
  padding-bottom: 45px; // invite members button space
}

.usersList {
  .userTile {
    margin-bottom: 10px;

    &:not(:first-of-type) {
      margin-top: 4px;
    }
  }

  .assetTile {
    margin-bottom: 6px;
  }
}

.assetsList {
  .assetTile {
    margin-bottom: 8px;
  }
}

.userTile,
.assetTile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: thin solid $color-vape;
  border-radius: 4px;
  background-color: $color-vape;

  &.selected {
    border-color: $color-oh-so-green;
  }
}

.userTile {
  height: 55px;
  padding-right: 8px;
  padding-left: 12px;

  &:hover {
    cursor: pointer;
  }

  .username {
    flex: 1;
    padding-left: 12px;
  }

  .userDataContainer {
    flex: 2;

    .userEmail {
      flex: 1;
      padding: 4px 0 0 12px;
      color: $color-metal-grey;
    }
  }

  .actions {
    display: flex;
    align-items: center;

    .newUserBlock {
      background: $color-ashy;
      color: $color-snow-in-april;
      display: flex;
      justify-content: center;
      padding: 4px 5px;
      border-radius: 4px;
      margin-right: 1.6rem;
    }

    .selectbox {
      width: 100px;
    }

    .multiselect {
      display: flex;

      &.firstHomeAssign {
        .selectbox {
          width: 130px;
        }
      }
    }

    .joinState {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: $color-metal-grey;
      color: $color-pretty-dark;

      &.accepted {
        background-color: $color-oh-so-green;
      }
    }
  }
}

.assetTile {
  position: relative;
  height: 40px;
  padding-right: 12px;
  padding-left: 12px;

  .clickable:hover {
    cursor: pointer;
  }

  &.isChildren {
    width: calc(100% - 22px);
  }

  &:not(.isChildren) {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 2px;
      height: 17px;
      background-color: $color-ace-blue;
      transform: translateY(-50%);
    }
  }

  .leftContainer {
    display: flex;
    align-items: center;
    flex: 1;

    .assetUser {
      font-family: 'NB Akademie Std';
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 12px;
      color: $color-metal-grey;
    }
  }

  .icon {
    color: $color-metal-grey;
    margin-right: 12px;
  }

  .name {
    flex: 1;
    // margin-left: 12px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
  }

  .childAssetsCount {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 75px;
    height: 16px;
    padding: 0 5px;
    border-radius: 4px;
    background-color: $color-white;
    color: $color-ashy;
    font-weight: 500;
    font-size: 9px;
    line-height: 7px;
  }

  .owners {
    display: flex;
    margin-left: 10px;

    &.hoverable {
      &:hover > div:not(:first-of-type) {
        // margin-left: 2px;
      }
    }

    .avatar {
      position: relative;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;

      button {
        position: absolute;
        top: -1px;
        left: -1px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        border-radius: 100%;
        background-color: $color-tundora;
        color: $color-white;
        opacity: 0;
        pointer-events: none;
      }

      &:hover button {
        opacity: 0.8;
        pointer-events: auto;
      }

      &:not(:first-of-type) {
        margin-left: -6px;
        transition: 0.3s;
      }
    }

    .appliedState {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $color-ferra;
      color: $color-white;
      opacity: 0.8;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    margin-left: 5px;

    > svg {
      color: $color-metal-grey;
    }
  }

  .actionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 3px;
    border-radius: 100%;
    background-color: $color-oh-so-green;
  }

  .actionButtonDecline {
    background-color: $color-singularity-red;
  }

  .selectbox {
    max-width: 120px;
    margin-left: 10px;
  }
}

.tileAvatar {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $color-metal-grey;
}

.appliedUserAvatarWrapper {
  position: relative;
  margin-right: 12px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -6px;
    height: 14px;
    border-right: thin solid $color-metal-grey;
    transform: translateY(-50%);
  }
}

.aggregatorInfo {
  width: 100%;
  margin-top: 45px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      color: $color-ashy;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }

    span {
      color: $color-metal-grey;
      font-size: 11px;
      font-family: $ff-sans-landingPage;
    }
  }

  p {
    margin-top: 20px;
    color: $color-metal-grey;
    font-size: 14px;
  }
}

.wifiCircle {
  background-color: $color-metal-grey;
  color: $color-ashy;
  padding: 5px;
  border-radius: 10px;
  margin-right: 10px;
}

.customToast {
  position: absolute;
  width: 35vw;
  min-width: 280px;
  max-width: 775px;
  padding: 15px 45px 15px 15px;
  border-radius: 7px;
  font-weight: $fw-medium;
  line-height: 1.36;

  position: absolute;
  z-index: 2;
  left: 0px;
  background: $color-error;
  color: $color-white;
}

.closeBtn {
  position: absolute;
  top: 15px;
  right: 15px;
}

.tooltipContent {
  width: 200px;
  font-family: $ff-sans !important;
  h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}
