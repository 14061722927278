@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  height: 100%;
}

.dataGrid {
  flex: 1;
}

.asset {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: $color-vape;
  width: 105px;
  min-height: 180px;
  position: relative;
  padding: 20px 0 12px 0;
  margin: 0 8px 12px 0;
  :global(.moreMenu) {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
  }
  :global(.content) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 5px;
    :global(.avatar) {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background-size: cover;
      &:global(.isLetter) {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        text-transform: uppercase;
        border: 1px solid $color-metal-grey;
      }
    }
    h6 {
      font-size: 11px;
      font-weight: 600;
      font-family: $ff-sans-landingPage;
      margin-bottom: 8px;
      text-align: center;
    }
    span {
      margin-bottom: 10px;
      color: $color-metal-grey;
      font-size: 9px;
    }
    :global(.roleSelector) {
      margin-bottom: 10px;
    }
    :global(.buildCanary) {
      width: 100%;
      height: 20px;
      border-radius: 4px;
      background-color: $color-burn-my-eyes;
      font-size: 11px;
      color: $color-pretty-dark;
      width: 140px;
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
    }
  }
  :global(.numbers) {
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 8px;
    padding-top: 12px;
    & > div {
      display: flex;
      align-items: center;
      padding: 4px 5px;
      border-radius: 4px;
      background-color: $color-metal-grey;
      color: $color-snow-in-april;
      svg {
        margin-right: 6px;
      }
    }
  }
}

.gridItem {
  .title {
    font-size: 1.1rem;
  }

  cursor: pointer;
  :global(.content) {
    p {
      font-size: 1rem;
      color: $color-kinda-dirty;
    }
  }
}

.tableItem {
  :global(.userDetails) {
    margin-left: 18px;
  }
  :global(.user) {
    width: fit-content;
    height: 26px;
    padding: 0 12px;
    border-radius: 13px;
    font-size: 1.2rem;
    color: $color-ace-blue;
    background-color: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  :global(.actions) {
    display: flex;
    align-items: center;
    height: 60%;
    border-left: thin solid $color-metal-grey;
    button {
      padding: 2px;
      margin-left: 18px;
    }
  }
}

.assetIcon {
  color: $color-metal-grey;
  margin-bottom: 10px;
}

.plugIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 6px;
  top: 6px;
  border-radius: 50%;
  background-color: $color-metal-grey;
  color: $color-white;
}
