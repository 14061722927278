@import 'src/assets/styles/utils/index.module.scss';

.wrapper {
  .header {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    h3 {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
    }
  }
  .container {
    display: flex;
    flex-direction: row;
    background-color: $color-white;
    padding: 3rem;
    gap: 2rem;
    margin-top: 1rem;
    .chartWrapper {
      position: relative;
      flex: 2;
      .total {
        position: absolute;
        width: 220px;
        height: 220px;
        text-align: center;
        z-index: 2;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
        }
        h3 {
          font-weight: 700;
          font-size: 30px;
          line-height: 32px;
        }
        span.today {
          font-weight: 500;
          font-size: 9px;
          line-height: 18px;
          color: $color-ace-blue;
          width: 33px;
          height: 18px;
          border-radius: 2px;
          border: 1px solid $color-ace-blue;
        }
      }
    }
    .listArea {
      display: flex;
      flex: 2;
      flex-direction: column;
      gap: 1rem;
      padding-left: 1rem;
    }
  }
}

.listItem {
  display: flex;
  flex-direction: row;
  border-radius: 0.4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: $color-snow-in-april;
  span {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding-right: 1rem;
    &:nth-child(1) {
      padding-left: 1rem;
      border-left: 2px solid;
      flex: 3;
    }
    &:nth-child(2) {
      flex: 1;
      justify-content: right;
    }
    &:nth-child(3) {
      flex: 1;
      justify-content: right;
    }
  }
}
