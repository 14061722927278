@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: absolute;
  background-color: #34363f;
  z-index: 0;
  display: flex;
  flex-direction: column;
  left: 50%;
  bottom: 5%;
  transform: translate(-50%, -50%);
  padding: 10px;
  color: $color-athens-gray;
  font-size: 16px;
  line-height: 24px;
  .InfoContainer {
    display: flex;
    flex-direction: row;
    div {
      padding: 0 0 0 10px;
    }
    h3 {
      color: $color-burn-my-eyes;
      font-size: 14px;
    }
  }
}
