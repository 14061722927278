@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  align-items: center;
  height: 20px;
  color: $color-metal-grey;
  font-size: 8px;
  justify-content: space-between;
}
