@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: absolute;
  top: $map-sidebar-top;
  bottom: 0;
  left: 0;
  z-index: $z-map-navigation;
  transition-duration: 300ms;
  transition-property: transform;
}

.sidebarWithoutNav {
  top: $map-sidebar-scm-top;
}

.containerEmbed {
  top: 105px;
}

.closed {
  transform: translateX(-100%);

  .closeControls {
    background-color: $color-pretty-dark;

    svg {
      color: $color-oh-so-green;
      transform: rotate(180deg);
    }
  }
}

.closeControls button {
  height: 40px;
}

.viewControls {
  top: 65px;

  button {
    height: 35px;
  }
}
