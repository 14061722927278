@import 'src/assets/styles/utils/index.module.scss';

// create a css class for a div that will contain the table and make it scrollable
.scrollableTable {
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #efeff1;
  height: 320px;
}

.tableBills {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0px none;

  .greyBackground {
    background-color: $color-vape;
  }
  thead {
    .darkTableHeader {
      background-color: $color-pretty-dark;
      color: $color-white;
      font-weight: 600;
      th {
        padding-top: 10px;
        padding-bottom: 10px;
        &.rightBorder {
          border-right: 1px solid $color-vape;
        }
      }
      th:first-child {
        border-top-left-radius: 5px;
      }
      th:last-child {
        border-top-right-radius: 5px;
      }
    }
    .columnTitlesHeader {
      color: $color-pretty-dark;
      th {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      th:first-child {
        font-weight: 600;
      }
    }
    .informationsHeader {
      th {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 11px;
        &.informationCell {
          border-left: 1px solid $color-vape;
          border-right: 1px solid $color-vape;
        }
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
      td {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 13px;
        text-align: center;
        align-content: center;
        vertical-align: middle;
        font-weight: 500;
      }
      td:first-child {
        font-weight: 400;
        text-align: left;
        span.borderLeft {
          border-left: 2px solid $color-black;
          padding-left: 5px;
        }
      }
    }
  }
  .textLeft {
    text-align: left;
    padding-left: 5px;
  }
  .redText {
    color: $color-singularity-red;
  }
  .greenText {
    color: $color-oh-so-green;
  }
  .blueText {
    color: $color-ace-blue;
  }
  .totalText {
    span {
      font-size: 11px;
      display: block;
      width: 100%;
      font-weight: 400;
    }
  }
}
