@import 'src/assets/styles/utils/index.module.scss';

.badge {
  display: inline-flex;
  align-items: center;
  height: 23px;
  padding: 10px;
  border-radius: 8px;
  color: $color-ashy;
  font-size: 1.1rem;
  margin-right: 10px;
  //  background-color: $color-oh-so-green;

  &.clickable {
    cursor: pointer;
  }

  &.sizeS {
    font-size: 0.8rem;
    height: 18px;
    padding: 5px 6px;
    background-color: $color-vape;
  }

  &.sizeM {
    background-color: $color-snow-in-april;
  }

  .titleWithAmount {
    font-weight: bold;
  }

  .titleWithIcon {
    margin-left: 5px;
  }

  .amount {
    margin-left: 5px;
  }
}
