@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: $color-snow-in-april;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 68px;
    padding: 0 14px;
    margin-bottom: 14px;
    border-bottom: 1px solid $color-vape;

    h3 {
      font-size: 1.8rem;
    }

    & > div {
      flex-shrink: 0;
    }
  }
}

.headerNav {
  display: flex;
  font-size: 1.6rem;
  width: 100%;

  li {
    margin-right: 20px;
    padding: 4px 0;
    color: $color-metal-grey;

    &.communityName {
      overflow: hidden;

      button {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.active {
      border-bottom: 2px solid $color-oh-so-green;
      color: $color-pretty-dark;
    }
    &.hasNotifications {
      color: $color-pretty-dark;
    }
  }
}

.tabsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  padding: 0 14px;
}

.basic,
.graphsList,
.settingsList,
.registry {
  overflow-y: scroll;
  height: 100%;
  padding: 0 14px;
  padding-bottom: 30px;
}

.formButton {
  width: 100%;
  border-radius: 0;
}
