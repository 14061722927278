@import 'src/assets/styles/utils/index.module.scss';

.container {
}

.block {
  border-radius: 5px;
  background-color: $color-vape;
}

.assetInfo {
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 10px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    width: 100%;
    height: 100px;
    // top: 0;
    padding: 16px 8px 16px;

    & > div {
      margin-bottom: 10px;

      &:last-of-type {
        width: fit-content;
      }
    }
  }

  .leftValues {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 8px;
  }

  .rightTag {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
  }

  .boxValues {
    box-sizing: border-box;

    p {
      margin-bottom: 8px;
      &:first-child {
        font-size: 0.9rem;
      }

      &:nth-child(2) {
        font-size: 1.6rem;
        font-weight: 700;
      }

      &:last-child {
        font-size: 1.1rem;
      }
    }

    &:first-child {
      p {
        color: $color-metal-grey;
      }
    }
  }

  .divider {
    border-left: 1pt solid $color-metal-grey;
    opacity: 0.3;
    width: 1;
    height: 100%;
    margin: 0 10px;
  }

  .cost {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 1px;
      background-color: $color-ashy;
      transform: translate(-50%, -50%);
    }
  }

  footer {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 25px;
  }
}

.assetIcon {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.assetName {
  font-weight: $fw-bold;
  font-size: 1.3rem;
}

.assetsCount {
  margin-top: 10px;
  font-size: 1.1rem;
}

.differenceInfoTag {
  display: flex;
  align-items: center;
  height: 23px;
  padding: 0 10px;
  border-radius: 8px;
  color: $color-ashy;
  font-size: 1.1rem;

  p {
    margin: 0 4px;
  }

  span {
    font-weight: $fw-bold;
  }

  &.earning {
    background-color: $color-ace-blue;
    color: $color-snow-in-april;
  }

  &.neutral {
    background-color: $color-london-fog;
  }

  &.saving {
    background-color: $color-oh-so-green;
  }

  &.losses {
    background-color: $color-soft-singularity-red;
  }

  &.mb {
    margin-bottom: 10px;
  }
}

.chartLoadingWrapper {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 20px);
    // https://online-free-tools.com/en/css_pattern_generator_background
    background-image: radial-gradient(circle, rgba($color-metal-grey, 0.3) 10%, transparent 10%),
      radial-gradient(circle, rgba($color-metal-grey, 0.3) 10%, transparent 10%);
    background-position: 0 0, 0 0;
    background-size: 10px 10px;
  }
}

.canvas {
  position: relative;
}
