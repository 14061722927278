@import 'src/assets/styles/utils/index.module.scss';

.container {
  &.themeDark {
    &.startCalendarActive .pickers .calendarIcon:first-of-type {
      color: $color-london-fog;
    }

    &.endCalendarActive .pickers .calendarIcon:last-of-type {
      color: $color-london-fog;
    }

    .pickers {
      background-color: $color-ashy;

      &::after {
        border-left-color: $color-pretty-dark;
      }

      .datepickerInput {
        color: $color-snow-in-april;
      }

      .calendarIcon {
        color: $color-oh-so-green;
      }
    }
    .tags button {
      background-color: $color-ashy;
      color: $color-snow-in-april;

      &.checked {
        border-color: $color-oh-so-green;
      }
    }
  }

  &.themeLight {
    &.startCalendarActive .pickers .calendarIcon:first-of-type {
      color: $color-ashy;
    }

    &.endCalendarActive .pickers .calendarIcon:last-of-type {
      color: $color-ashy;
    }

    .pickers {
      background-color: $color-vape;

      &::after {
        border-left-color: $color-london-fog;
      }

      .datepickerInput {
        color: $color-ashy;
      }

      .calendarIcon {
        color: $color-oh-so-green;
      }
    }

    .tags button {
      background-color: $color-vape;
      color: $color-ashy;

      &.checked {
        border-color: $color-oh-so-green;
      }
    }
  }

  &.calendarOpened {
    .pickers {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .customHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background-color: $color-ashy;

      &:disabled {
        opacity: 0.2;
      }

      svg {
        color: $color-oh-so-green;
      }
    }
  }
}

.pickers {
  position: relative;
  display: flex;
  height: 28px;
  border-radius: 4px;

  &::after {
    content: '';
    position: absolute;
    top: 15%;
    left: 50%;
    width: 0;
    height: 70%;
    border-left-width: thin;
    border-left-style: solid;
  }

  .datepickerInput {
    width: 100%;
    height: 100%;
    padding: 0 8px;
    font-size: 1.3rem;
  }

  .calendarIcon {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 12px;
    height: 11px;
    pointer-events: none;
    transform: translate(-150%, -50%);

    &:first-of-type {
      left: 50%;
    }

    &:last-of-type {
      left: 100%;
    }
  }
}

.tags {
  display: flex;
  justify-content: space-between;
  padding-top: 18px;

  button {
    position: relative;
    height: 28px;
    padding: 0 15px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: $color-ashy;
    color: $color-snow-in-april;

    &.checked {
      border-color: $color-oh-so-green;
    }

    .badge {
      position: absolute;
      top: 100%;
      left: 50%;
      font-weight: $fw-medium;
      font-size: 0.9rem;
      transform: translate(-50%, 50%);
    }
  }
}
