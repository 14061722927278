@import 'src/assets/styles/utils/index.module.scss';

.inputEmail {
  margin-bottom: 25px;
}

.inputPassword {
  margin-bottom: 30px;
}

.buttonLogin {
  width: 100%;
}

.queryMessage {
  margin-bottom: 10px;
}

.recaptchaContainer {
  margin-bottom: 8px;
}

.formLoginSCM {
  margin-top: 45px;

  .inputEmail {
    margin-bottom: 15px;
    width: 100%;
  }

  .inputPassword {
    margin-bottom: 15px;
    width: 100%;
  }

  .buttonLogin {
    width: 100%;
    margin-bottom: 25px;
  }

  .fieldsContainer {
    display: flex;
    gap: 20px;
  }

  .startText {
    margin-bottom: 25px;
    font-family: $ff-sans;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: $color-metal-grey;
  }

  .forgotPassword {
    font-family: $ff-sans;
    font-weight: 400;
    line-height: 14px;
    cursor: pointer;
    color: $color-oh-so-green;
    margin: 0 0 4rem 0;
    font-size: 1.2rem;
    text-decoration: underline;
    text-underline-offset: 5px;
  }

  .contactText {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      width: 300px;
      font-family: $ff-sans;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: $color-snow-in-april;
    }

    span {
      color: $color-oh-so-green;
    }
  }
}
