@import 'src/components/ModalCollaborationDeploy/ModalCollaborationDeploy.module.scss';

.background {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
}

.modalUploadDataSheet {
  border-radius: $modalBorderRadius;
  padding: 0;

  header {
    padding: 4rem 0;
    background: $color-ashy;
    width: 100%;

    p {
      margin-bottom: 0;
    }
  }

  .body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3rem;
    width: 100%;

    label {
      width: 100%;
    }
  }

  .uploadSection {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: $ff-sans;
    padding: 2rem;
    width: 100%;
    height: 200px;
    border: 1px dashed $color-snow-in-april;
    cursor: pointer;

    img {
      margin-bottom: 10px;
    }

    .title {
      font-size: 17px;
      line-height: 20px;
      color: $color-snow-in-april;
      margin-bottom: 1rem;
    }

    .subTitle {
      font-size: 13px;
      line-height: 15px;
      color: $color-metal-grey;
      margin-bottom: 1.3rem;
    }

    svg {
      color: $color-oh-so-green;
      margin-bottom: 1.5rem;
    }
  }

  h2.modalTitle {
    margin-bottom: 1rem;
  }

  .uploadBtn {
    width: 100%;
    margin-top: 3rem;
  }

  .plainLink {
    font-size: 15px;
    line-height: 18px;
  }

  .downloadLink {
    color: $color-oh-so-green;
    font-size: 15px;
    line-height: 18px;
    position: absolute;
  }
}
