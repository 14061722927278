@import 'src/assets/styles/utils/index.module.scss';

.bgRed {
  background-color: $color-singularity-red;
}

.bgGreen {
  background-color: $color-oh-so-green;
}

.bgBlue {
  background-color: $color-ace-blue;
}

.badgeRow {
  flex-direction: row;
}
