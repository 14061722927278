@import 'src/assets/styles/utils/index.module.scss';

.miniItem {
  display: flex;
  background-color: $color-snow-in-april;
  margin-bottom: 5px;
  height: 30px;
  border-radius: 4px;
  padding: 8px 8px 8px 0px;
  align-items: center;
  justify-content: space-between;
  span {
    padding-left: 3px;
    flex: 1;
  }
  .energy {
    flex: 1;
    text-align: right;
  }
  .title {
    font-weight: 700;
    flex: 1;
    color: $color-ashy;
    padding-left: 10px;
  }
  .titleBorderImport {
    border-left: solid $color-oh-so-green 2px;
  }
  .titleBorderExport {
    border-left: solid $color-singularity-red 2px;
  }
  .titleBorderNeutral {
    border-left: solid $color-ace-blue 2px;
  }
}
