@import 'src/assets/styles/utils/index.module.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  p {
    font-size: 1.8rem;
  }
}

.typeSelector {
  h5 {
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 8px;
  }
  p {
    font-size: 1.2rem;
    font-weight: 400;
    color: $color-metal-grey;
    max-width: 260px;
  }
}

.hero {
  width: 100%;
  height: 230px;
  background-color: $color-vape;
  border-radius: 5px;
  padding: 20px 18px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  h5 {
    font-size: 1.6rem;
  }
}

.navigation {
  display: flex;
  margin-top: 30px;
  margin-bottom: 20px;
  button {
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 400;
    margin-right: 30px;
    &.active {
      font-weight: 700;
      border-bottom: 2px solid $color-oh-so-green;
    }
  }
}
