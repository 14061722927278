@import 'src/assets/styles/utils/index.module.scss';

.content {
  display: flex;
  flex-direction: column;
  height: 500px;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  position: relative;
  margin-left: 26px;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    height: auto;
    max-height: fit-content;
  }

  img {
    margin: 0 0 70px 0px;

    @media only screen and (min-width: 300px) and (max-width: 900px) {
      display: none;
    }

    @media only screen and (min-width: 300px) and (max-width: 1500px) {
      max-width: 50%;
    }
  }
}

.menuBox {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 7%;

  span {
    margin-bottom: 4rem;

    &:first-child {
      margin-top: 1rem;
    }
  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    span {
      margin-bottom: 3rem;
    }
  }
}

.itemMenu {
  font-size: 2.2rem;
  color: rgba($color-snow-in-april, 0.3);
  font-family: $ff-sans-landingPage;
  font-weight: $fw-medium;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    font-size: 1.5rem;
  }
}

.itemMenu:hover {
  color: $color-snow-in-april;
  cursor: pointer;
  -webkit-transition: color 2s ease-out;
  -moz-transition: color 2s ease-out;
  -o-transition: color 2s ease-out;
  transition: color 2s ease-out;
}

.itemMenuActive {
  color: $color-snow-in-april;
  font-family: $ff-sans-secondary;
  font-weight: $fw-medium;
  letter-spacing: -1px;
  font-size: 6rem;
  transition: 0.3s;

  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    font-size: 3rem;
  }
}

.copyrightContainer {
  position: fixed;
  left: 20px;
  bottom: 25px;
  display: flex;
  align-items: center;

  .copyrightText {
    font-family: $ff-sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $color-metal-grey;
    margin-right: 20px;
  }

  .privacyPolicyLink {
    font-family: $ff-sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $color-snow-in-april;
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
}
